import React from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { t } from "ttag";
import classNames from "classnames";
import {
    IAddressCountry,
    IAddressState,
    IAddress,
} from "../../../models/address.interfaces";
import {
    FinancingAccountInquiry,
    FinancingPlan,
} from "../../../models/financing";
import { IMonths, isoAPR, isoMonths } from "../../../models/nominals";
import format, { formatTermLength } from "../../../utils/format";
import { isElement } from "../../../utils/guards";
import { urls } from "../../../utils/urls";
import { isFortivaAcctNum } from "../utils";
import { FormSubmit } from "../../../forms/FormSubmit";
import { FormInput } from "../../../forms/FormInput";
import { FormNumber } from "../../../forms/FormNumber";
import { FormCheckbox } from "../../../forms/FormCheckbox";
import { FinancingModalTrigger } from "../../financing/FinancingModalTrigger";
import { strings } from "../../../localization";
import {
    IFinancingPayment,
    IReduxFormState,
    IReduxDerivedData,
} from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { SelectPlanForm } from "./PaymentMethod_Financing_SelectPlanForm";
import { FinePrint } from "./PaymentMethod_Financing_FinePrint";
import { RequestLimitIncrease } from "./PaymentMethod_Financing_RequestLimitIncrease";
import { IFormErrors } from "./PaymentMethod.interfaces";
import { Trans } from "../../../common/Trans";
import { Link } from "../../../common/Link";
import iconLock from "../../../../img/checkout/lock.png";

import fortivaFinancingStyles from "./PaymentMethod_Financing_Fortiva.module.scss";

interface IProps {
    methodKey: string;
    countries: IAddressCountry[];
    billing_states: IAddressState[];
    savedAddresses: IAddress[];
    form: IReduxFormState;
    errors: {
        [method_key: string]: IFormErrors;
    };
    showErrorMessages: boolean;
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    changeFinancingAccountNumber: Dispatchers["changeFinancingAccountNumber"];
    changeAmount: Dispatchers["changeAmount"];
    onValidStateChange: (field: string, errors: string[]) => void;
    plans: FinancingPlan[];
    grandTotal: string;
    allowCreditApplications: boolean;
    switchToCreditCard: () => void;
    newAccount?: FinancingAccountInquiry;
    basketTotal?: number;
    cartID: string;
    header: string | null;
    isSplitPay: boolean;
    shouldFreezeAmount: boolean;
    disabled: boolean;
    derived: IReduxDerivedData;
    addSplitPayToFinancing: () => void;
    enableSplitPay: boolean;
}

interface IState {}

type TPrintTypes = "welcome" | "terms";

export class AddAccountForm extends React.Component<IProps, IState> {
    private currentPlanMonths = 0;
    private termsElem: HTMLElement | null = null;
    private welcomeElem: HTMLElement | null = null;

    private readonly onAccordionClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const targetElem = event.currentTarget;
        if (targetElem.classList.contains("accordion--is-active")) {
            targetElem.classList.remove("accordion--is-active");
            if (targetElem.nextElementSibling) {
                targetElem.nextElementSibling.classList.remove(
                    "accordion-target--is-active",
                );
            }
        } else {
            const accordionElems = document.getElementsByClassName(
                "accordion--is-active",
            );
            const accordionTargetElems = document.getElementsByClassName(
                "accordion-target--is-active",
            );
            while (accordionElems.length > 0) {
                accordionElems[0].classList.remove("accordion--is-active");
            }
            while (accordionTargetElems.length > 0) {
                accordionTargetElems[0].classList.remove(
                    "accordion-target--is-active",
                );
            }
            targetElem.classList.add("accordion--is-active");
            if (targetElem.nextElementSibling) {
                targetElem.nextElementSibling.classList.add(
                    "accordion-target--is-active",
                );
            }
        }
        const anchor = document.getElementById(
            "financing-new-account__accordion-anchor",
        );
        if (anchor) {
            anchor.scrollIntoView();
        }
    };

    private readonly onAccountInfoClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const anchor = document.getElementById("financing-new-account__anchor");
        if (anchor) {
            anchor.scrollIntoView();
        }
    };

    private readonly onPrintWelcome = (
        event: React.MouseEvent | React.KeyboardEvent,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.openPrintWindow("welcome");
    };

    private readonly onPrintTerms = (
        event: React.MouseEvent | React.KeyboardEvent,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.openPrintWindow("terms");
    };

    private readonly onSetPlanMonths = (months: IMonths) => {
        this.currentPlanMonths = isoMonths.unwrap(months);
    };

    private readonly onAcctNumChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.changeFinancingAccountNumber(
            this.props.methodKey,
            event.currentTarget.value,
        );
    };

    private readonly onCheckboxChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.setPaymentMethodFields(this.props.methodKey, {
            [event.currentTarget.name]: event.currentTarget.checked,
        });
    };

    private readonly onAmountChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const amount = event.currentTarget.value;
        this.props.changeAmount(
            this.props.methodKey,
            this.props.derived.grand_total,
            amount,
        );

        // check if amount is lower than plan threshold
        const paymentMethod = this.props.form.payment_methods[
            this.props.methodKey
        ] as IFinancingPayment;
        if (paymentMethod.financing_plan) {
            const amountFloat = parseFloat(amount.replace(/[^0-9.]+/g, ""));
            const currentPlan = this.props.plans.find((plan) => {
                return plan.id === paymentMethod.financing_plan;
            });
            if (
                currentPlan &&
                amountFloat < parseFloat(currentPlan.product_price_threshold)
            ) {
                // try to find a plan with lower threshold
                let planId = currentPlan.id;
                for (const plan of this.props.plans) {
                    if (
                        parseFloat(plan.product_price_threshold) <= amountFloat
                    ) {
                        planId = plan.id;
                    }
                }
                // update to the lesser plan if one was found
                if (planId !== currentPlan.id) {
                    this.props.setPaymentMethodFields(this.props.methodKey, {
                        financing_plan: planId,
                    });
                }
            }
        }
    };

    private readonly onSplitPay = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }
        const anchor = document.getElementById("financing-header__anchor");
        if (anchor) {
            anchor.scrollIntoView();
        }
        this.props.addSplitPayToFinancing();
    };

    private readonly onPrintScroll = () => {
        if (window.pageYOffset.toFixed() === "0") {
            window.removeEventListener("scroll", this.onPrintScroll);
            // TODO: Determine why setTimeout is necessary. Seems to be a race condition between the eventListener and the actual scrolling
            setTimeout(() => {
                window.print();
            }, 1);
        }
    };

    private getMethodData(): IFinancingPayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let wellsFargoData: IFinancingPayment | null = null;
        if (data.method_type === "financing") {
            wellsFargoData = data;
        }
        const baseFields: IFinancingPayment = {
            method_type: "financing",
            amount: "",
            pay_balance: false,
            new_financing_account: null,
            financing_account: "",
            financing_plan: null,
            has_agreed: false,
            has_esigned: false,
        };
        return {
            ...baseFields,
            ...wellsFargoData,
        };
    }

    private openPrintWindow(printType: TPrintTypes) {
        const messageElems: { [t in TPrintTypes]: HTMLElement | null } = {
            welcome: this.welcomeElem,
            terms: this.termsElem,
        };
        const messageElem = messageElems[printType];
        if (!messageElem) {
            return;
        }
        const width = 640;
        const height = 480;
        const options = [
            ["toolbar", "no"],
            ["location", "no"],
            ["directories", "no"],
            ["status", "no"],
            ["menubar", "no"],
            ["scrollbars", "yes"],
            ["resizable", "yes"],
            ["width", width],
            ["height", height],
            ["top", screen.height / 2 - height / 2],
            ["left", screen.width / 2 - width / 2],
        ];
        const encodedOptions = options
            .map((pair) => {
                return pair.join("=");
            })
            .join(", ");
        const popupTitles: { [t in TPrintTypes]: string } = {
            welcome: t`Wells Fargo Account Details`,
            terms: t`Wells Fargo Terms and Conditions`,
        };
        const popup = window.open("", popupTitles[printType], encodedOptions);
        if (!popup) {
            return;
        }
        const messageText = isElement(messageElem)
            ? messageElem.innerHTML
            : messageElem;
        if (document.head && popup.document.head) {
            popup.document.head.innerHTML = document.head.innerHTML;
        }
        popup.document.body.innerHTML = `
            <div class="financing-form__welcome" style="margin: 20px;">
                ${messageText}
                <button class="button no-print" onclick="javascript:window.print()">Print</button>
                <button class="button no-print" onclick="javascript:window.close()">Close</button>
            </div>
        `;
        popup.print();
    }

    private buildApplyBlock() {
        const bestPlan = this.props.plans
            .filter((plan) => {
                return parseFloat(isoAPR.unwrap(plan.apr)) <= 0;
            })
            .reduce<FinancingPlan | null>((memo, plan) => {
                return memo && memo.term_months >= plan.term_months
                    ? memo
                    : plan;
            }, null);
        let qualificationMsg: JSX.Element;
        if (bestPlan) {
            qualificationMsg = (
                <li className="checkout-step-apply-block__list-item">
                    Your order qualifies for 0% APR for{" "}
                    {formatTermLength(bestPlan.term_months)} months
                    <sup>{bestPlan.fine_print_superscript}</sup>
                </li>
            );
        } else {
            const defaultThreshold = strings.get(
                "FINANCING_DEFAULT_PLAN_THRESHOLD",
            );
            const defaultTermMonths = strings.get(
                "FINANCING_DEFAULT_PLAN_TERM_MONTHS",
            );
            const defaultAPR = strings.get("FINANCING_DEFAULT_PLAN_APR") || "0";
            const defaultSuperscript = strings.get(
                "FINANCING_DEFAULT_PLAN_SUPERSCRIPT",
            );
            qualificationMsg = (
                <li className="checkout-step-apply-block__list-item">
                    {interpolate(
                        t`Orders of %(defaultThreshold)s or more qualify for %(apr)s APR for %(termMonths)s months`,
                        {
                            defaultThreshold: `${format.money(
                                defaultThreshold,
                            )}`,
                            apr: `${parseInt(defaultAPR, 10)}`,
                            termMonths: `${defaultTermMonths}`,
                        },
                    )}
                    <sup>{defaultSuperscript}</sup>
                </li>
            );
        }
        return (
            <div className="checkout-step-apply-block">
                <div className="checkout-step-apply-block__header">
                    <div className="checkout-step-apply-block__header-block">
                        <h4 className="checkout-step-apply-block__copy checkout-step-apply-block__copy--bold">
                            {strings.get("FINANCING_APPLY_CTA_HEAD")}
                        </h4>
                        <div className="checkout-step-apply-block__copy">
                            {strings.get("FINANCING_APPLY_CTA_SUBHEAD")}
                        </div>
                        <a
                            className="checkout-step-apply-block__link checkout-step-apply-block__link--desktop"
                            href="#consent-form"
                        >
                            Financing Terms
                        </a>
                    </div>
                    <div className="checkout-step-apply-block__header-block">
                        <ul className="checkout-step-apply-block__list">
                            <li className="checkout-step-apply-block__list-item">
                                No annual fee<sup>1</sup>
                            </li>
                            <li className="checkout-step-apply-block__list-item">
                                Quick and secure online application
                            </li>
                            <li className="checkout-step-apply-block__list-item">
                                Exclusive Special Financing Offers
                            </li>
                            {qualificationMsg}
                        </ul>
                        <Link
                            className="checkout-step-apply-block__link checkout-step-apply-block__link--mobile"
                            href={urls.pageURL("finance-link")}
                            target="_top"
                        >
                            Financing Terms
                        </Link>
                    </div>
                </div>
                <div className="checkout-step-apply-block__button-container">
                    <FinancingModalTrigger
                        className="button financing-form__button al-financing-form__apply-now-button"
                        modalType="full-app"
                    >
                        {t`Apply Now`}
                    </FinancingModalTrigger>
                </div>
            </div>
        );
    }

    buildCreditAppHeader() {
        const methodData = this.getMethodData();
        return (
            <div
                className="financing-form__terms"
                tabIndex={0}
                id="consent-form"
            >
                <div className="financing-form__terms-header">
                    <h4 className="financing-form__terms-title checkout-step__copy--bold">
                        {strings.get("FINANCING_CONSENT_DELIVERY_HEAD")}
                    </h4>
                    <a
                        href="#print"
                        className="financing-form__terms-print"
                        onClick={() => {
                            window.addEventListener(
                                "scroll",
                                this.onPrintScroll,
                            );
                            window.scrollTo(0, 0);
                        }}
                        title={`Print ${strings.get("FINANCING_CONSENT_BODY")}`}
                    >
                        print
                    </a>
                </div>
                <div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html:
                                strings.get(
                                    "FINANCING_CONSENT_DELIVERY_BODY",
                                ) || "",
                        }}
                    ></p>
                    <p
                        className="financing-form__terms-copy"
                        ref={(elem) => {
                            this.termsElem = elem;
                        }}
                        dangerouslySetInnerHTML={{
                            __html: strings.get("FINANCING_CONSENT_BODY") || "",
                        }}
                    ></p>
                </div>
                <div>
                    <a
                        href="#print"
                        className="checkout-step__copy--underline"
                        onClick={this.onPrintTerms}
                        title={`Print ${strings.get("FINANCING_CONSENT_BODY")}`}
                    >
                        <h4 className="checkout-step__copy--bold">
                            {strings.get("FINANCING_CONSENT_ESIGN_HEAD")}
                        </h4>
                    </a>
                    <FormCheckbox
                        label={t`I consent to the terms and conditions of ESIGN Consent to receive documents electronically. I am able to receive and view email and PDF files.`}
                        name="has_agreed"
                        checked={methodData.has_agreed}
                        onChange={this.onCheckboxChange}
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
        );
    }

    buildMinFinancingAmount(plans: number[], isFortiva: boolean) {
        if (isFortiva) {
            return 0;
        }
        return plans.length ? Math.min(...plans) : 0;
    }

    render() {
        const methodData = this.getMethodData();

        const basketTotal = this.props.basketTotal || 0;
        const isTotalCovered =
            this.props.newAccount &&
            parseFloat(this.props.newAccount.credit_limit) >= basketTotal;
        let totalDifference: string | null = null;
        if (this.props.newAccount) {
            totalDifference = isTotalCovered
                ? "0.00"
                : (
                      Math.round(
                          1000 *
                              (basketTotal -
                                  parseFloat(
                                      this.props.newAccount.credit_limit,
                                  )),
                      ) / 1000
                  ).toFixed(2);
        }

        let header: JSX.Element | null = null;
        if (this.props.allowCreditApplications) {
            header = this.buildCreditAppHeader();
        }

        const submitClasses = classNames({
            "button": true,
            "button--full-width": true,
            "button--place-order": true,
            [`checkout-place-order-button--wf-${this.currentPlanMonths}`]: true,
            [`al-checkout__place-order-button--wf-${this.currentPlanMonths}`]:
                true,
        });

        const errors =
            this.props.methodKey in this.props.errors
                ? this.props.errors[this.props.methodKey]
                : {};

        const plansAboveZero = this.props.plans
            .map((plan) => parseFloat(plan.product_price_threshold))
            .filter((amount) => amount > 0);
        const minFinancingAmount = this.buildMinFinancingAmount(
            plansAboveZero,
            isFortivaAcctNum(methodData.financing_account),
        );

        return (
            <div>
                <h4
                    className="checkout-step__bar-heading"
                    id="financing-header__anchor"
                >
                    {this.props.header}
                </h4>
                {this.props.newAccount ? (
                    <div>
                        <div
                            className="financing-new-account--inline"
                            id="financing-new-account__anchor"
                        >
                            <div
                                className="financing-new-account__header"
                                ref={(elem) => {
                                    this.welcomeElem = elem;
                                }}
                            >
                                <div className="financing-new-account__header-copy">
                                    <span className="financing-new-account__header-copy--bold">
                                        {t`Congratulations, you're approved!`}
                                    </span>
                                </div>
                                <div className="financing-new-account__header-details">
                                    <div className="financing-new-account__header-number">
                                        <div className="financing-new-account__header-number--title">
                                            {t`Your account number:`}
                                        </div>
                                        <span className="financing-new-account__header-number--bold">
                                            {format.wellsFargoAccountNumber(
                                                methodData.financing_account,
                                            )}
                                        </span>
                                    </div>
                                    <div className="financing-new-account__header-number">
                                        <div className="financing-new-account__header-number--title">
                                            {t`Your approved credit:`}
                                        </div>
                                        <span className="financing-new-account__header-number--bold">
                                            {format.money(
                                                this.props.newAccount
                                                    .credit_limit,
                                            )}
                                        </span>
                                    </div>
                                    <a
                                        href="#print"
                                        title={t`Print Special Terms and APR Information`}
                                        className="financing-new-account__print"
                                        onClick={this.onPrintWelcome}
                                    >
                                        print
                                    </a>
                                </div>
                            </div>
                        </div>
                        {!isTotalCovered ? (
                            <div className="financing-new-account__deficit">
                                <div
                                    className="financing-new-account__warning"
                                    id="financing-new-account__accordion-anchor"
                                >
                                    <div className="financing-new-account__warning-icon-container">
                                        <div className="financing-new-account__warning-icon">
                                            !
                                        </div>
                                    </div>
                                    <div className="financing-new-account__warning-copy">
                                        <div>
                                            <span className="financing-new-account__warning-copy--bold">
                                                {t`Order total exceeds your available credit limit.`}
                                            </span>
                                            <br />
                                            {interpolate(
                                                t`Your order total exceeds your credit limit by %s. View your options below.`,
                                                [
                                                    `${format.money(
                                                        totalDifference,
                                                    )}`,
                                                ],
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="financing-new-account__deficit-options accordion__container">
                                    {this.props.enableSplitPay ? (
                                        <div>
                                            <div>
                                                {t`Choose an option to complete your order:`}
                                            </div>
                                            <ul className="accordion__list accordion__list--checkout-finance">
                                                <li className="accordion__list-item accordion__list-item--checkout-finance">
                                                    <a
                                                        className="accordion accordion--checkout-finance accordion--arrow"
                                                        onClick={
                                                            this
                                                                .onAccordionClick
                                                        }
                                                    >
                                                        {t`Use multiple payment methods`}
                                                    </a>
                                                    <div className="accordion-target accordion-target--checkout-finance">
                                                        <span className="financing-new-account__arrow-down"></span>
                                                        <span className="financing-new-account__sub-header-copy--bold">
                                                            {t`Multiple Payment Methods`}
                                                        </span>
                                                        <div>
                                                            {interpolate(
                                                                t`Utilize your approved credit limit of %(creditLimit)s and pay the difference of %(difference)s with a major credit card. To do this, we'll take you to our split pay option to enter your additional payment details.`,
                                                                {
                                                                    creditLimit: `${format.money(
                                                                        this
                                                                            .props
                                                                            .newAccount
                                                                            .credit_limit,
                                                                    )}`,
                                                                    difference: `${format.money(
                                                                        totalDifference,
                                                                    )}`,
                                                                },
                                                            )}
                                                        </div>
                                                        <div className="financing-new-account__warning">
                                                            <div className="financing-new-account__warning-icon-container">
                                                                <div className="financing-new-account__warning-icon">
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div className="financing-new-account__warning-copy">
                                                                <div>
                                                                    <span className="financing-new-account__warning-copy--bold">
                                                                        {t`Your privacy is important to us`}
                                                                    </span>
                                                                    <br />
                                                                    <Trans
                                                                        fmtString={t`For security reasons we don't store your account number. Please take note of or print your <AccountInfoClick>account information</AccountInfoClick> now. You will not have access to it again until you receive your card in the mail.`}
                                                                        data={{
                                                                            AccountInfoClick:
                                                                                (
                                                                                    content,
                                                                                ) => (
                                                                                    <a
                                                                                        key="1"
                                                                                        onClick={
                                                                                            this
                                                                                                .onAccountInfoClick
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            content
                                                                                        }
                                                                                    </a>
                                                                                ),
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a
                                                                className="button button--full-width"
                                                                onClick={
                                                                    this
                                                                        .onSplitPay
                                                                }
                                                            >
                                                                {t`Go to Split Pay`}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="accordion__list-item accordion__list-item--checkout-finance">
                                                    <a
                                                        className="accordion accordion--checkout-finance accordion--arrow"
                                                        onClick={
                                                            this
                                                                .onAccordionClick
                                                        }
                                                    >
                                                        {t`Request a credit limit increase`}
                                                    </a>
                                                    <RequestLimitIncrease
                                                        methodKey={
                                                            this.props.methodKey
                                                        }
                                                        form={this.props.form}
                                                        plans={this.props.plans}
                                                        grandTotal={
                                                            this.props
                                                                .grandTotal
                                                        }
                                                        setPaymentMethodFields={
                                                            this.props
                                                                .setPaymentMethodFields
                                                        }
                                                        disabled={
                                                            this.props.disabled
                                                        }
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    ) : (
                                        <div className="accordion__list-item accordion__list-item--checkout-finance">
                                            <a
                                                className="accordion accordion--checkout-finance accordion--arrow"
                                                onClick={this.onAccordionClick}
                                            >
                                                {t`Request a credit limit increase`}
                                            </a>
                                            <RequestLimitIncrease
                                                methodKey={this.props.methodKey}
                                                form={this.props.form}
                                                plans={this.props.plans}
                                                grandTotal={
                                                    this.props.grandTotal
                                                }
                                                setPaymentMethodFields={
                                                    this.props
                                                        .setPaymentMethodFields
                                                }
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <span>
                                <div className="financing-form__plan-select financing-new-account__plan-select">
                                    <SelectPlanForm
                                        methodKey={this.props.methodKey}
                                        form={this.props.form}
                                        plans={this.props.plans}
                                        grandTotal={this.props.grandTotal}
                                        setPlanMonths={this.onSetPlanMonths}
                                        setPaymentMethodFields={
                                            this.props.setPaymentMethodFields
                                        }
                                        disabled={this.props.disabled}
                                    />
                                </div>
                                <FormSubmit
                                    value={
                                        strings.get(
                                            "CONTINUE_BTN_LABEL_PAYMENT_METHODS",
                                        ) || t`Place Order`
                                    }
                                    className={submitClasses}
                                />
                                <FinePrint />
                            </span>
                        )}
                    </div>
                ) : (
                    <>
                        <div className="checkout-step__form-container checkout-step__form-container--financing checkout-step__form-container--blue">
                            <fieldset
                                disabled={this.props.disabled}
                                style={{ border: 0 }}
                            >
                                <legend className="ada-screenreader-only">
                                    Account Information
                                </legend>
                                <h3>
                                    <label
                                        className="checkout-step__copy--bold"
                                        htmlFor="financing_account"
                                    >
                                        Account Number
                                    </label>
                                </h3>
                                <FormInput
                                    name="financing_account"
                                    id="financing_account"
                                    type="text"
                                    autoComplete="cc-number"
                                    validation={[
                                        "required",
                                        "financing-account",
                                    ]}
                                    value={format.wellsFargoAccountNumber(
                                        methodData.financing_account,
                                    )}
                                    errors={errors.financing_account}
                                    onValidStateChange={
                                        this.props.onValidStateChange
                                    }
                                    showErrorMessages={
                                        this.props.showErrorMessages
                                    }
                                    onChange={this.onAcctNumChange}
                                    maxLength={19}
                                    secureImage={{
                                        image: iconLock,
                                        alt: t`Secure`,
                                    }}
                                />
                                {this.props.isSplitPay && (
                                    <div>
                                        <label
                                            className="checkout-step__copy--bold"
                                            htmlFor="financing-amount"
                                        >
                                            Amount
                                        </label>
                                        <FormNumber
                                            name="amount"
                                            id="financing-amount"
                                            autoComplete=""
                                            step={0.01}
                                            validation={[
                                                "required",
                                                "amount",
                                                "nonzero",
                                            ]}
                                            showErrorMessages={true}
                                            onValidStateChange={
                                                this.props.onValidStateChange
                                            }
                                            value={
                                                methodData.amount || undefined
                                            }
                                            errors={errors.amount}
                                            onChange={this.onAmountChange}
                                            disabled={
                                                this.props.shouldFreezeAmount
                                            }
                                            min={minFinancingAmount}
                                        />
                                    </div>
                                )}
                                {this.props.disabled && (
                                    <div>
                                        <h5>{t`Pre-Authorized`}</h5>
                                        <p>
                                            {interpolate(
                                                t`This account has been pre-authorized for the amount of %s.`,
                                                [
                                                    format.money(
                                                        methodData.amount ||
                                                            "0.00",
                                                    ),
                                                ],
                                            )}
                                        </p>
                                    </div>
                                )}
                                {!this.props.isSplitPay &&
                                    !isFortivaAcctNum(
                                        methodData.financing_account,
                                    ) &&
                                    this.buildApplyBlock()}
                            </fieldset>
                            {!isFortivaAcctNum(
                                methodData.financing_account,
                            ) && (
                                <>
                                    <div className="financing-form__plan-select">
                                        <SelectPlanForm
                                            methodKey={this.props.methodKey}
                                            form={this.props.form}
                                            plans={this.props.plans}
                                            grandTotal={this.props.grandTotal}
                                            setPlanMonths={this.onSetPlanMonths}
                                            setPaymentMethodFields={
                                                this.props
                                                    .setPaymentMethodFields
                                            }
                                            disabled={this.props.disabled}
                                        />
                                    </div>
                                    {header}
                                    <div className="financing-form__esign">
                                        <h4 className="checkout-step__copy--bold">
                                            Electronic Signature
                                        </h4>
                                        <FormCheckbox
                                            label={t`By checking this box, I acknowledge that this is my electronic signature and that this transaction is under my Credit Card Agreement with Wells Fargo Bank, N.A. I acknowledge receipt of the following documents at the time I opened my account:`}
                                            name="has_esigned"
                                            checked={methodData.has_esigned}
                                            onChange={this.onCheckboxChange}
                                            disabled={this.props.disabled}
                                        />
                                        <ul>
                                            <li>Credit Card Agreement</li>
                                            <li>
                                                Important Terms of Your Credit
                                                Card Account
                                            </li>
                                            <li>
                                                Wells Fargo Retail Services
                                                Privacy Notice
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                        {isFortivaAcctNum(methodData.financing_account) && (
                            <>
                                <p className={fortivaFinancingStyles.terms}>
                                    {t`By clicking Place Order, the above transaction will be financed by your Fortiva® Retail Credit Account in accordance with the Terms and Conditions previously provided to you.`}
                                </p>
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }
}
